@import "../../../variables.scss";
/* Social link element */
.social-element {
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
	/* Name */
	.social-platform-name {
		color: $color_blue_1;
		text-shadow: 0px 3px 6px rgba($color_black_0, 0.32);
		font-family: BoonTook Mon;
		font-size: 1.7rem;
		text-decoration: none;
		@include for-size(tablet, down) {
			font-size: 1.4rem;
		}
		&:first-child {
			margin-top: 0;
		}
	}
	/* Link */
	.social-platform-link {
		color: $color_gray_1;
		font-size: 1.2rem;
		text-shadow: 0px 3px 6px rgba($color_black_0, 0.16);
		font-weight: bold;
		text-decoration: none;
	}
}
