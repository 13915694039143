@import "../../../variables.scss";

.details-box {
	background: linear-gradient(180deg, #343c54, #0a0c11);
	opacity: 0.95;
	border-radius: 15px;
	padding: 3rem;
	@include for-size(mobile, down) {
		padding: 2rem;
	}
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	h2 {
		color: $color_white_0;
		text-shadow: 0px 3px 6px rgba($color_black_0, 0.32);
		font-family: BoonTook Mon;
		font-size: 1.7rem;
		@include for-size(tablet, down) {
			font-size: 1.4rem;
		}
		margin: 0 0 1rem 0;
	}
	h3 {
		color: $color_blue_1;
		text-shadow: 0px 3px 6px rgba($color_black_0, 0.32);
		font-family: BoonTook Mon;
		font-size: 1.7rem;
		@include for-size(tablet, down) {
			font-size: 1.4rem;
		}
		margin: 0 0 1rem 0;
	}
	p {
		margin: 0 0 1rem 0;
	}
	button {
		flex: 1 1 0;
		min-width: 0;
		align-self: flex-end;
	}
}
