/* SCSS HEX */
$dark-purple: #160c28ff;
$orange-yellow-crayola: rgb(249, 194, 43);
$honeydew: #e1efe6ff;
$ash-gray: #aeb7b3ff;
$rich-black-fogra-29: #000411ff;

.music-player-progressbar {
	display: flex;
	justify-content: stretch;
	width: 100%;
	margin: 15px auto;
	.MuiBox-root {
		margin: 0 auto;
	}
}
