@import "../../../variables.scss";

.rjs-button-outlined {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	background-color: transparent;
	outline: 0;
	border: 0;
	margin: 0;
	border-radius: 0;
	padding: 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	-moz-appearance: none;
	-webkit-appearance: none;
	-webkit-text-decoration: none;
	text-decoration: none;
	color: inherit;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.75;
	letter-spacing: 0.02857em;
	text-transform: uppercase;
	min-width: 64px;
	padding: 5px 15px;
	padding-right: 15px;
	padding-left: 15px;
	border-radius: 4px;
	-webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	// border: 1px solid rgba(25, 118, 210, 0.5);
	// border-top-color: rgba(25, 118, 210, 0.5);
	// border-right-color: rgba(25, 118, 210, 0.5);
	// border-bottom-color: rgba(25, 118, 210, 0.5);
	// border-left-color: rgba(25, 118, 210, 0.5);
	// color: #1976d2;

	color: $color_white_0 !important;
	border-color: $color_white_0 !important;
	text-transform: none;
	align-self: flex-end;
	padding-left: 35px;
	padding-right: 35px;
	&:hover {
		color: $color_black_0 !important;
		background-color: $color_white_0 !important;
		border-color: $color_black_0 !important;
	}
}
