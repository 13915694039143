.persisting-url {
	display: grid;
	width: 380px;
	justify-content: center;
	flex-direction: column;
	margin: 50px auto;
	min-height: 100vh;
	align-content: flex-start;
	h1,
	h2 {
		color: white;
		text-align: center;
	}
	.buttons-container {
		text-align: center;
		button {
			margin: 5px;
		}
	}
}
