div.persisting-localstsorage {
	display: grid;
	justify-content: center;
	padding: 2rem;
	min-height: 100vh;
	align-content: flex-start;
	h1,
	h2 {
		color: white;
		text-align: center;
		width: 50%;
		margin: 1vmax auto;
	}
	.buttons-container {
		text-align: center;
		button {
			margin: 5px;
		}
	}
}
