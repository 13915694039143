/* SCSS HEX */
$dark-purple: #160c28ff;
$orange-yellow-crayola: #efcb68ff;
$honeydew: #e1efe6ff;
$ash-gray: #aeb7b3ff;
$rich-black-fogra-29: #000411ff;

.music-player-controls{
    display: flex;
    justify-content: center;
    svg {
        fill:$ash-gray;
        width: 40px;
        height: 40px;
        margin: 0 15px;
    }
}