@import "../../Accordion.scss";

.darkmode-switcher {
	position: fixed;
	top: 20px;
	right: 20px;
	z-index: 10;

	svg.MuiSvgIcon-root {
		width: 50px;
		height: 50px;
	}
	&.dark {
		svg.MuiSvgIcon-root {
			fill: $gainsboro;
		}
	}
	&.light {
		svg.MuiSvgIcon-root {
			fill: $eerie-black;
		}
	}
}
