/*Global SCSS Variables*/

/* COLORS */

$color_white_0: #ffffff;

$color_black_0: #000000;
$color_black_1: #111;

$color_gray_1: #c9c9c9;
$color_gray_2: #707070;

$color_blue_1: #00d8ff;
$color_blue_2: #202538;

/*Media queries breakpoints*/
$query__mobile_mini: 420px;
$query__mobile: 760px;
$query__tablet: 1025px;
$query__desktop: 1367px;
$query__ultrahd: 1921px;
$query__4k: 2561px;

/* Element First Media Queries
========================================================= */

// A map of breakpoints.
$breakpoints: (
	mobile: $query__mobile,
	mobile_mini: $query__mobile_mini,
	tablet: $query__tablet,
	desktop: $query__desktop,
	ultrahd: $query__ultrahd,
	4k: $query__4k,
);

@mixin for-size($breakpoint, $direction: down) {
	// If the breakpoint exists in the map.
	@if map-has-key($breakpoints, $breakpoint) {
		// Get the breakpoint value.
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		// Determine the direction and then write the media query.
		@if $direction == up {
			@media (min-width: $breakpoint-value) {
				@content;
			}
		} @else if $direction == down {
			@media (max-width: $breakpoint-value) {
				@content;
			}
		}
	}
	// If the breakpoint doesn't exist in the map, pass a manual breakpoint
	@else {
		@if $direction == up {
			@media (min-width: $breakpoint) {
				@content;
			}
		} @else if $direction == down {
			@media (max-width: $breakpoint) {
				@content;
			}
		}
	}
}

/* MARGINS & PADDINGS */

/* General padding settings if you want left and right empty white space on your side */

$k4k_padding_left: 4vw;
$k4k_padding_right: 4vw;

$ultrahd_padding_left: 3vw;
$ultrahd_padding_right: 3vw;

$desktop_padding_left: 2vw;
$desktop_padding_right: 2vw;

$laptop_padding_left: 4vw;
$laptop_padding_right: 4vw;

$tablet_padding_left: 3vw;
$tablet_padding_right: 3vw;

$mobile_padding_left: 2vw;
$mobile_padding_right: 2vw;

/* General padding settings if you want left and right empty white space on your side */

$k4k_margin_left: $k4k_padding_left;
$k4k_margin_right: $k4k_padding_right;

$ultrahd_margin_left: $ultrahd_padding_left;
$ultrahd_margin_right: $ultrahd_padding_right;

$desktop_margin_left: $desktop_padding_left;
$desktop_margin_right: $desktop_padding_right;

$laptop_margin_left: $laptop_padding_left;
$laptop_margin_right: $laptop_padding_right;

$tablet_margin_left: $tablet_padding_left;
$tablet_margin_right: $tablet_padding_right;

$mobile_margin_left: $mobile_padding_left;
$mobile_margin_right: $mobile_padding_right;

@mixin padding-width {
	@include for-size(desktop, up) {
		padding-left: $desktop_padding_left;
		padding-right: $desktop_padding_right;
	}

	@include for-size(ultrahd, up) {
		padding-left: $ultrahd_padding_left;
		padding-right: $ultrahd_padding_right;
	}

	@include for-size(4k, up) {
		padding-left: $k4k_padding_left;
		padding-right: $k4k_padding_right;
	}

	@include for-size(desktop, down) {
		padding-left: $laptop_padding_left;
		padding-right: $laptop_padding_right;
	}

	@include for-size(tablet, down) {
		padding-left: $tablet_padding_left;
		padding-right: $tablet_padding_right;
	}

	@include for-size(mobile, down) {
		padding-left: $mobile_padding_left;
		padding-right: $mobile_padding_right;
	}
}

@mixin margin-width {
	@include for-size(desktop, up) {
		margin-left: $desktop_margin_left;
		margin-right: $desktop_margin_right;
	}

	@include for-size(ultrahd, up) {
		margin-left: $ultrahd_margin_left;
		margin-right: $ultrahd_margin_right;
	}

	@include for-size(4k, up) {
		margin-left: $k4k_margin_left;
		margin-right: $k4k_margin_right;
	}

	@include for-size(desktop, down) {
		margin-left: $laptop_margin_left;
		margin-right: $laptop_margin_right;
	}

	@include for-size(tablet, down) {
		margin-left: $tablet_margin_left;
		margin-right: $tablet_margin_right;
	}

	@include for-size(mobile, down) {
		margin-left: $mobile_margin_left;
		margin-right: $mobile_margin_right;
	}
}

@mixin -margin-width {
	@include for-size(desktop, up) {
		margin-left: -$desktop_margin_left;
		margin-right: -$desktop_margin_right;
	}

	@include for-size(ultrahd, up) {
		margin-left: -$ultrahd_margin_left;
		margin-right: -$ultrahd_margin_right;
	}

	@include for-size(4k, up) {
		margin-left: -$k4k_margin_left;
		margin-right: -$k4k_margin_right;
	}

	@include for-size(desktop, down) {
		margin-left: -$laptop_margin_left;
		margin-right: -$laptop_margin_right;
	}

	@include for-size(tablet, down) {
		padding-left: -$tablet_margin_left;
		padding-right: -$tablet_margin_right;
	}

	@include for-size(mobile, down) {
		margin-left: -$mobile_margin_left;
		margin-right: -$mobile_margin_right;
	}
}

/* Responsive image */
.responsive-image,
.img-fluid {
	width: 100%;
	height: auto;
}

p {
	line-height: 1.6;
}
