@import "../../../variables.scss";

/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}

.screen-reader-text:focus {
	background-color: #eee;
	clip: auto !important;
	clip-path: none;
	color: #444;
	display: block;
	font-size: 1em;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
}

/*Project box design*/
.project {
	margin-bottom: 2vh;
	//Image of the project
	.image-container {
		position: relative;
		display: block;
		width: 350px;
		height: 300px;
		@include for-size(tablet, down) {
			width: 320px;
			height: 250px;
		}
		@include for-size(mobile, down) {
			width: 280px;
			height: 200px;
		}
		.image-url {
			display: flex;
			height: 100%;
			width: 100%;

			/* Image style */
			picture {
				display: flex;
				img {
					border-radius: 5px;
					display: flex;
					width: 100%;
					object-fit: cover;
					object-position: top;
				}
			}
		}
		.github-url {
			position: absolute;
			bottom: 15px;
			@include for-size(tablet, down) {
				bottom: 5px;
			}
			right: 15px;
			svg {
				background-color: $color_black_0;
				border-radius: 50%;
			}
			/* svg icon style */
			.github-icon {
				fill: $color_white_0;
			}
		}
	}
	//Title of the project
	.project-url {
		text-decoration: none;
		.project-title {
			color: $color_blue_1;
			text-shadow: 0px 3px 6px rgba($color_black_0, 0.32);
			font-family: BoonTook Mon;
			font-size: 1.7rem;
			text-decoration: none;
			@include for-size(tablet, down) {
				font-size: 1.4rem;
			}
			margin: 1rem 0 0.5rem 0;
		}
	}
	//Tags of twhe project
	.project-tags {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
		width: 350px;
		@include for-size(tablet, down) {
			width: 320px;
		}
		@include for-size(mobile, down) {
			width: 280px;
		}
		/*Individual tag*/
		.project-tag {
			font-size: 0.9rem;
			border-radius: 20px;
			background-color: $color_black_0;
			color: $color_white_0;
			padding: 8px 15px 4px 15px;
			margin-right: 10px;
			margin-bottom: 10px;
		}
	}
}
