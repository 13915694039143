@import "../../../variables.scss";

.recoil-todo-container {
	max-width: 100%;
	min-height: 100vh;
	background-color: $color_white_0;
	display: grid;
	justify-content: center;
	align-content: flex-start;
	padding: 5vmax;
	.recoil-todo-title {
		text-align: center;
	}
	form {
		display: flex;
		@include for-size(mobile, down) {
			flex-direction: column;
			justify-content: stetch;
		}
	}
	.recoil-todolist {
		justify-self: center;
	}
}
