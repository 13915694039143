@import "../../../variables.scss";

.infobox {
	.infobox-title {
		color: $color_blue_1;
		font-size: 1.8rem;
		@include for-size(mobile, down) {
			font-size: 1.6rem;
		}
		margin: 0.5rem 0;
	}
	.infobox-text {
		color: $color_gray_1;
		font-size: 1.4rem;
		@include for-size(mobile, down) {
			font-size: 1.2rem;
		}
		font-weight: bold;
		line-height: 1.5;
	}
	.infobox-link {
		color: $color_white_0;
		font-size: 1.4rem;
		@include for-size(mobile, down) {
			font-size: 1.2rem;
		}
		text-decoration: none;
		font-weight: bold;
		transition: 0.25s linear;
		&:hover {
			opacity: 0.8;
		}
	}
}
