@import "../../../../../variables.scss";

.item-inner {
	input[type="text"] {
		&.task-is-done {
			text-decoration: line-through;
			border: 2px solid green;
		}

		/* 		&.task-is-open {
		} */
	}
	button.finish.task-is-done {
		filter: grayscale(100%);
	}
}
