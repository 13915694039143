$body_background: #0b0b0b;
$circle_color: #3498db;
$circle_background: #e3e3e3;

.page-loader {
	position: relative;
	z-index: 9999;
	&.page-loading {
		display: block;
	}
	&.page-loaded {
		display: none;
	}
	.loader-wrapper {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background-color: $body_background;
		display: grid;
		place-items: center;
	}

	.loader {
		border: 16px solid $circle_background;
		border-top: 16px solid $circle_color;
		border-radius: 50%;
		width: 140px;
		height: 140px;
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}
