@import "../../../variables.scss";

.certificate {
	display: flex;
	flex-direction: column;
	@include for-size(mobile, down) {
		max-width: 300px;
		margin: 0 auto;
	}
	img {
		height: 250px;
		object-fit: cover;
	}

	a {
		text-decoration: none;
		color: white;
		margin-top: 1rem;
	}
}
