@import "../../../variables.scss";
.calculator-container {
	min-height: 100vh;
	display: grid;
	place-items: center;
	.calculator-wrapper {
		display: block;
		padding: 4rem;
		background-color: white;
		max-width: 50vw;
		margin: 5vmax auto;
		@include for-size(mobile, down) {
			max-width: 90vw;
		}
		h1 {
			text-align: center;
		}
		.display {
			display: block;
			border: 2px solid black;
			padding: 15px;
			flex: 1 1 100%;
			text-align: end;
		}
		.buttons {
			display: flex;
			justify-content: center;
			flex-direction: row;
			flex-wrap: wrap;
			button {
				margin: 3px;
			}
		}
		.result {
			margin-top: 2rem;
			text-align: center;
			strong {
				margin-right: 15px;
			}
		}
	}
	.button-panel {
		display: grid;
		grid-template-columns: repeat(4, 50px);
		grid-template-rows: repeat(5, 50px);
		grid-template-areas:
			"seven eight nine divide"
			"four five six multiply"
			"one two three deduction"
			"AC zero dot add"
			" . . . equal";
		.button-0 {
			grid-area: zero;
		}
		.button-1 {
			grid-area: one;
		}
		.button-2 {
			grid-area: two;
		}
		.button-3 {
			grid-area: three;
		}
		.button-4 {
			grid-area: four;
		}
		.button-5 {
			grid-area: five;
		}
		.button-6 {
			grid-area: six;
		}
		.button-7 {
			grid-area: seven;
		}
		.button-8 {
			grid-area: eight;
		}
		.button-9 {
			grid-area: nine;
		}
		.button-AC {
			grid-area: AC;
			background-color: black;
			color: white;
		}
		.button-plus {
			grid-area: add;
		}
		.button-deduct {
			grid-area: deduction;
		}
		.button-X {
			grid-area: multiply;
		}
		.button-÷ {
			grid-area: divide;
		}
		.button-dot {
			grid-area: dot;
		}
		.button-equal {
			grid-area: equal;
			background-color: aqua;
		}
	}
}
